<template>
  <v-list-item v-if="item" :disabled="isDisabled">
    <v-list-item-content>
      <v-list-item-title v-text="item.name" />
      <v-list-item-subtitle v-text="item.info" />
    </v-list-item-content>

    <v-list-item-action>
      <div>
        <v-btn
          v-if="showApply"
          class="mr-2"
          color="primary"
          small
          text
          @click="onToggleAutoApply"
        >
          <icon-checkbox-checked v-if="isAutoApply" class="mr-2" />
          <icon-checkbox-unchecked v-else class="mr-2" />
          {{ $t(sAutoApplyLabel) }}
        </v-btn>

        <v-btn
          v-if="showCopy"
          :disabled="isDisabled"
          class="mr-2"
          color="primary"
          small
          text
          @click="onCopy"
        >
          <icon-check-circle class="mr-2" />
          {{ $t("assign") }}
        </v-btn>

        <v-btn v-if="!hideUpdate" color="primary" icon text @click="onSelect">
          <icon-edit />
        </v-btn>

        <v-btn v-if="!hideDelete" color="error" icon text @click="onRemove">
          <icon-trash />
        </v-btn>
      </div>
    </v-list-item-action>
  </v-list-item>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import type { Label } from "@planetadeleste/vue-mc-gw";
import IconTrash from "@/components/icons/IconTrash.vue";
import IconCheckCircle from "@/components/icons/IconCheckCircle.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import IconCheckboxChecked from "@/components/icons/IconCheckboxChecked.vue";
import IconCheckboxUnchecked from "@/components/icons/IconCheckboxUnchecked.vue";

@Component({
  components: {
    IconCheckboxUnchecked,
    IconCheckboxChecked,
    IconEdit,
    IconCheckCircle,
    IconTrash,
  },
})
export default class CompanyLabelItem extends Vue {
  @Prop(Object) readonly item!: Label;
  @Prop(Boolean) readonly hideDelete!: boolean;
  @Prop(Boolean) readonly hideUpdate!: boolean;
  @Prop(Boolean) readonly showCopy!: boolean;
  @Prop(Boolean) readonly showApply!: boolean;

  loading = false;

  get isDisabled(): boolean {
    return this.item && this.item.get("disabled", false);
  }

  get isAutoApply(): boolean {
    return this.item && this.item.get("is_autoapply", false);
  }

  get sAutoApplyLabel(): "label.remove.auto.apply" | "label.set.auto.apply" {
    return this.isAutoApply
      ? "label.remove.auto.apply"
      : "label.set.auto.apply";
  }

  async onToggleAutoApply() {
    this.loading = true;
    this.item.set("is_autoapply", !this.isAutoApply);
    this.item.sync();
    await this.item.store();
    this.loading = false;
  }

  onSelect() {
    this.$emit("update", this.item);
  }

  onCopy() {
    this.$emit("copy", this.item);
  }

  onRemove() {
    this.$emit("delete", this.item);
  }
}
</script>
